exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manicure-js": () => import("./../../../src/pages/manicure.js" /* webpackChunkName: "component---src-pages-manicure-js" */),
  "component---src-pages-pedicure-js": () => import("./../../../src/pages/pedicure.js" /* webpackChunkName: "component---src-pages-pedicure-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-waxing-js": () => import("./../../../src/pages/waxing.js" /* webpackChunkName: "component---src-pages-waxing-js" */),
  "component---src-templates-gallery-item-js": () => import("./../../../src/templates/galleryItem.js" /* webpackChunkName: "component---src-templates-gallery-item-js" */)
}

